h1 {
  text-align: center !important;
}

img {
  max-width: 100%;
}

.navbar-brand {
  max-width: 190px;

}

#random-tweet {
  text-align: center;
  max-width: 700px;

  img {
    width: 100%;
    margin: 0 auto;
  }
}

.character {
  padding: 5px 0;
}

#character-row {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

#landing-page-wrapper {
  background-color: rgba(255,255,255,0.95);
  position: fixed;
  z-index:10000;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  height: 100vh;

  p {
    display: block;
    font-size: 18px;
    padding: 5px;
    margin: 0;
  }

  @media only screen and (min-width: 1024px) {

    width: 100%;

    #page-header-img {
      width: 500px;
      margin: 0 auto;
    }

    #trump-logo {
      width: 300px;
    }

    p {
      font-size: 18px;
    }

    #slydeveloper-logo-link {
      width: 100px;
    }
  }

}

#btn-play-game-wrapper {
  margin-bottom: 48px;
}

#btn-play-game {
  width: 75%;
  font-size: 24px;
  font-weight: bold;
  padding: 24px;
  background-color: #d00;
  color: #fff;
  margin: 48px auto;
  border-radius: 24px;
}

// TODO: use for layout alignment then discard
@media only screen and (min-width: 1024px) {
  body {
    //background-color: lightblue;
  }
}

#page-header-img {
  display: block;
}

#trump-logo {
  width: 50%;
  display: block;
  margin: 0 auto;
}

#slydeveloper-logo-link {
  display: block;
  width: 30%;
  margin: 20px auto;
}
